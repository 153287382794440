import { default as _91_46_46_46slug_93bKscHo0VhuMeta } from "/webapp/pages/[...slug].vue?macro=true";
import { default as portaInCpk77KTJMeta } from "/webapp/pages/auth/porta.vue?macro=true";
import { default as callbackCdEewvrnTfMeta } from "/webapp/pages/callback.vue?macro=true";
import { default as _91_46_46_46slug_93OOwUW3o5kaMeta } from "/webapp/pages/embed/[...slug].vue?macro=true";
import { default as errorfbwL4vb9nkMeta } from "/webapp/pages/error.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93bKscHo0VhuMeta || {},
    component: () => import("/webapp/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "auth-porta",
    path: "/auth/porta",
    meta: portaInCpk77KTJMeta || {},
    component: () => import("/webapp/pages/auth/porta.vue").then(m => m.default || m)
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("/webapp/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: "embed-slug",
    path: "/embed/:slug(.*)*",
    component: () => import("/webapp/pages/embed/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/webapp/pages/error.vue").then(m => m.default || m)
  }
]