import { FontStyleModel } from '@/models/general/FontModel'
import Palette from '~/models/palette/Palette';
import { formatWidth } from "~/models/layout/style";
import { getBorder } from '@/models/general/BorderModel'
import { getGradient } from '@/models/general/GradientModel'

export class MenuRowStyleModel {
  constructor() {
    this.padding = null
    this.margin = null
    this.borderWidth = null
    this.borderRadius = null
    this.borderStyle = null
    this.borderColor = null
    this.gap = null
    this.fontStyle = null
    this.backgroundColor = ''
    this.backgroundHoverColor = ''
  }

  fromData({
    padding = null,
    margin = null,
    borderProperties = null,
    gap = null,
    fontProperties = null,
    backgroundColor = '',
    backgroundHoverColor = '',
    width = null,
    alignment = null,
  }) {
    if (gap) this.gap = `${gap}px`
    if (width) this.width = width
    if (alignment) this.textAlign = alignment

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (borderProperties) {
      const { borderWidth, borderRadius, borderStyle, borderColor } = getBorder(borderProperties)
      this.borderWidth = borderWidth
      this.borderRadius = borderRadius
      this.borderStyle = borderStyle

      const palette1 = new Palette()
      palette1.checkColor(borderColor)
      this.borderColor = palette1.color
    }


    const palette2 = new Palette()
    palette2.checkColor(backgroundColor)
    this.backgroundColor = palette2.color

    const palette3 = new Palette()
    palette3.checkColor(backgroundHoverColor)
    this.backgroundHoverColor = palette3.color

    if (fontProperties) {
      const fontStyle = new FontStyleModel()
      fontStyle.fromData(fontProperties)
      this.fontStyle = fontStyle
    }
  }
}

export class MenuBurgerStyleModel {
  constructor() {
    this.padding = null
    this.background = ''
    this.backgroundColor = null
    this.opacity = null
    this.gap = null
    this.fontStyle = null
    this.alignItems = null
    this.menuWidth = null
  }

  fromData({
    backgroundColor = '',
    gradient = null,
    opacity = null,
    padding = null,
    gap = null,
    fontProperties = null,
    alignment = null,
    menuWidth = null,
  }) {

    const palette = new Palette()
    palette.checkColor(backgroundColor)
    this.backgroundColor = palette.color

    // this.opacity = `${opacity}%`
    this.menuWidth = formatWidth(menuWidth)
    if (gap) this.gap = `${gap}px`
    if (gradient) this.background = getGradient(gradient)
    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`

    if (fontProperties) {
      const fontStyle = new FontStyleModel()
      fontStyle.fromData(fontProperties)
      this.fontStyle = fontStyle
    }

    if (alignment) {
      switch (alignment) {
        case 'center':
          this.alignItems = 'center'
          break
        case 'right':
          this.alignItems = 'flex-end'
          break
        default:
          this.alignItems = 'flex-start'
      }
    }
  }
}
