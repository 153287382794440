<script setup>
import { JHeaderSearchV2 } from "media-flow-ui"

import { usePageBuilder } from "~/stores/page-builder"

import Search from "~~/models/layout/search/Search"

import { formatSearchStyle } from "@/helpers/header/search"
import { formatIconStyle } from "~/helpers/icon/icon"
import { $$t } from '~~/utils/i19m'

const pageBuilderStore = usePageBuilder()

// const authBuilderStore = useAuthBuilder();

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  breakpoint: {
    type: String,
    required: true,
  },
  isOpen: {
    type: Boolean,
    required: true,
  },
  onToggle: {
    type: Function,
    required: true,
  },
  onClose: {
    type: Function,
    default: () => { },
    required: false,
  },
  searchEnterCallback: {
    type: Function,
    required: false,
    default: null,
  },
  searchValue: {
    type: String,
    required: false,
    default: "",
  },
})

const searchData = ref([])

// const skip = ref(0);
// const take = ref(10);
// const total = ref(null);

const loadMoreSearchData = async (data) => {
  // if (skip.value > total.value) return;

  // skip.value += take.value;

  const params = {
    take: 10, //take.value,
    skip: 0, //skip.value,
  }

  let response
  const encodeQueryUrl = encodeURIComponent(data)

  try {
    response = await pageBuilderStore.actionSearchMedia(params, encodeQueryUrl)
  } catch (error) {
    console.error(error)
  }

  if (!response) response = []

  searchData.value = response.map((rawSearchItem) => {
    const searchItem = new Search()
    searchItem.fromSearch(rawSearchItem)

    return searchItem
  })
}

const formattedData = computed(() => {
  return {
    ...props.data,
    style: formatSearchStyle(props.data.style),
  }
})

const wrapperStyle = computed(() => {
  const style = formatSearchStyle(props.data.style)
  return {
    padding: style.padding,
    margin: style.margin,
    borderRadius: style.borderRadius,
    borderWidth: style.borderWidth,
    borderColor: style.borderColor,
  }
})

const iconsStyle = formatIconStyle(props.data.icon)
</script>

<template>
  <JHeaderSearchV2 :class="'order-' + data.order" :is-open="isOpen" :on-toggle="onToggle" :on-close="onClose"
    :breakpoint="breakpoint" :data="formattedData" :search-data="searchData" :searchCallback="loadMoreSearchData"
    :wrapperStyle="wrapperStyle" :icons="iconsStyle" :icon="iconsStyle" :client-search-value="searchValue"
    :searchEnterCallback="searchEnterCallback" :noSearchDataText="$$t('component_search_no_results')" />
</template>

<style></style>
