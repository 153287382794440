import { computed, ref } from 'vue'

// Define the available locales and default locale
const availableLocales = ['en', 'fr', 'cz', 'sl']
const defaultLocale = 'cz'

// Create a ref for the current locale
const currentLocale = ref(defaultLocale)

// Create an object to store translations
const translations = {
  en: {
    welcome: 'Welcomeee',
    page_search_no_results_title: 'No search results',
    page_search_no_results_row_description_one: 'Sorry, but nothing was found',
    page_search_no_results_row_description_two:
      'You can search for a specific video or show',
    page_search_no_results_row_description_three:
      'Check your search term for typos',
    Videos: 'Videos',
    component_details_show_more: 'Show more',
    component_details_show_less: 'Show less',
    component_details_share: 'Share',
    component_details_views_count: 'views',
    component_details_views_online_count: 'online views',
    component_details_video_start_time: 'Start time',
    component_details_copy: 'Copy',
    component_details_link: 'Link',
    component_comments_comments: 'Comments',
    component_comments_cancel: 'Cancel',
    component_comments_confirm: 'Confirm',
    component_comments_reply: 'Reply',
    component_comments_replies: 'REPLIES',
    component_related_title: 'Related videos',
    page_login_callback_logging_in: 'Logging in',
    component_header_profile_log_out: 'Log out',
    component_chat_callback_login: 'Login',
    page_server_error_title: '500',
    page_server_error_description:
      "An error has occured and we are working to fix the problem! We'll be up and running shortly.",
    page_not_found_title: '404',
    page_not_found_description:
      "Sorry, but the page you requested was not found. Make sure you haven't made a mistake in the URL. The page may have been moved or deleted.",
    component_dropdown_sort_by_text: 'Sort by',
    'Newest to Oldest': 'Newest to Oldest',
    'Oldest to Newest': 'Oldest to newest',
    'A-Z': 'A - Z',
    'Z-A': 'Z - A',
    'Most Watched': 'Most Watched',
    component_redirecting_to_login_text: 'Redirecting to login',
    component_chat_chat_ended: 'Chat has ended',
    component_chat_paused: 'Chat has been paused',
    component_chat_scheduled: 'Chat scheduled. Will start soon.',
    component_chat_requires_login: 'Log in if you want to interact with chat.',
    component_chat_inactive: 'Chat is not active',
    component_chat_replay: 'Chat replay is active',
    component_chat_button_hide_chat: 'Hide Chat',
    component_chat_button_show_chat: 'Show Chat',
    component_chat_button_participants: 'Participants',
    component_chat_button_timestamps: 'Timestamps',
    component_chat_button_send: 'Send',
    component_chat_input_placeholder: 'Write a message...',
    component_card_new_text: 'New',
    component_player_product_placement: 'This video contains product placement',
    component_player_private_media: 'Private media',
    component_search_no_results: 'No results',
    'Pořady': 'Shows',
    'Kategorie': 'Categories',

  },
  cz: {
    welcome: 'Mendim',
    page_search_no_results_title: 'Omlouváme se, ale nic jsme nenašli',
    page_search_no_results_row_description_one:
      'Omlouváme se, ale nic jsme nenašli',
    page_search_no_results_row_description_two:
      'Můžete hledat konkrétní video nebo pořad',
    page_search_no_results_row_description_three:
      'Zkontrolujte, zda v hledaném výrazu nemáte překlepy',
    Videos: 'Videa',
    component_details_show_more: 'Zobrazit více',
    component_details_show_less: 'Zobrazit méně',
    component_details_share: 'Sdílet',
    component_details_views_count: 'zhlédnutí',
    component_details_views_online_count: 'online zobrazení',
    component_details_video_start_time: 'Čas spuštení videa',
    component_details_copy: 'Kopírovat',
    component_details_link: 'Odkaz',
    component_comments_comments: 'Komentáře',
    component_comments_cancel: 'Zrušit',
    component_comments_confirm: 'Potvrdit',
    component_comments_reply: 'Odpovědět',
    component_comments_replies: 'ODPOVĚDI',
    component_related_title: 'Následující videa',
    page_login_callback_logging_in: 'Přihlášení',
    component_header_profile_log_out: 'Odhlášení',
    component_chat_callback_login: 'Přihlášení',
    page_server_error_title: '500',
    page_server_error_description:
      'Došlo k chybě a na odstranění problému pracujeme! Za chvíli budeme v provozu.',
    page_not_found_title: '404',
    page_not_found_description:
      'Je nám líto, ale požadovaná stránka nebyla nalezena. Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.',
    component_dropdown_sort_by_text: 'Třídit podle',
    'Newest to Oldest': 'Nejnovější',
    'Oldest to Newest': 'Nejstarší',
    'A-Z': 'Abecedně A-Z',
    'Z-A': 'Abecedně Z-A',
    'Most Watched': 'Nejsledovanější',
    component_redirecting_to_login_text: 'Přesměrování na přihlášení',
    component_chat_chat_ended: 'Chat byl ukončen',
    component_chat_paused: 'Chat byl pozastaven',
    component_chat_scheduled: 'Chat je naplánován a brzy začne',
    component_chat_requires_login:
      'Pokud se chcete zapojit v chatu, přihlaste se prosím.',
    component_chat_inactive: 'Chat není aktivní',
    component_chat_replay: 'Záznam chatu se přehrává',
    component_chat_button_hide_chat: 'Skrýt chat',
    component_chat_button_show_chat: 'Zobrazit chat',
    component_chat_button_participants: 'Aktivní uživatelé',
    component_chat_button_timestamps: 'Časová razítka',
    component_chat_button_send: 'Poslat',
    component_chat_input_placeholder: 'Napište zprávu...',
    component_card_new_text: 'Nové',
    component_player_product_placement: 'Toto video obsahuje product placement',
    component_player_private_media: 'Soukromá média',
    component_search_no_results: 'Žádné výsledky hledání',
    'Pořady': 'Pořady',
    'Kategorie': 'Kategorie',
  },
  sl: {
    welcome: 'Dobrodošli',
    page_search_no_results_title:
      'Ni rezultatov iskanja / Iskanje ni vrnilo rezultatov.',
    page_search_no_results_row_description_one:
      'Oprostite, vendar ni bilo nič najdeno',
    page_search_no_results_row_description_two:
      'Lahko iščete določen video ali oddajo',
    page_search_no_results_row_description_three:
      'Preverite, ali so v iskalnem izrazu napake',
    Videos: 'Videi',
    component_details_show_more: 'Pokaži več',
    component_details_show_less: 'Pokaži manj',
    component_details_share: 'Deli',
    component_details_views_count: 'ogledi',
    component_details_views_online_count: 'ogledi na spletu',
    component_details_video_start_time: 'Začetni čas',
    component_details_copy: 'Kopiraj',
    component_details_link: 'Povezava',
    component_comments_comments: 'Komentarji',
    component_comments_cancel: 'Prekliči',
    component_comments_confirm: 'Potrdi',
    component_comments_reply: 'Odgovori',
    component_comments_replies: 'ODGOVORI',
    component_related_title: 'Sorodni videi',
    page_login_callback_logging_in: 'Prijava v teku',
    component_header_profile_log_out: 'Odjava',
    component_chat_callback_login: 'Prijava',
    page_server_error_title: '500',
    page_server_error_description:
      'Prišlo je do napake in delamo na rešitvi problema! Kmalu bomo spet na voljo',
    page_not_found_title: '404',
    page_not_found_description:
      'Oprostite, vendar zahtevana stran ni bila najdena. Prepričajte se, da v URL-ju ni napake. Stran je morda premaknjena ali izbrisana.',
    component_dropdown_sort_by_text: 'Razvrsti po',
    'Newest to Oldest': 'Najnovejši do najstarejši',
    'Oldest to Newest': 'Najstarejši do najnovejši',
    'A-Z': 'A - Z',
    'Z-A': 'Z - A',
    'Most Watched': 'Najbolj gledano',
    component_redirecting_to_login_text: 'Preusmeritev na prijavo',
    component_chat_chat_ended: 'Klepet je končan',
    component_chat_paused: 'Klepet je bil zaustavljen',
    component_chat_scheduled: 'Klepet je načrtovan. Začel se bo kmalu.',
    component_chat_requires_login:
      'Prijavite se, če želite sodelovati v klepetu.',
    component_chat_inactive: 'Klepet ni aktiven',
    component_chat_replay: 'Predvajanje klepeta je aktivno',
    component_chat_button_hide_chat: 'Skrij klepet',
    component_chat_button_show_chat: 'Pokaži klepet',
    component_chat_button_participants: 'Udeleženci',
    component_chat_button_timestamps: 'Časovni žigi',
    component_chat_button_send: 'Pošlji',
    component_chat_input_placeholder: 'Napiši sporočilo...',
    component_card_new_text: 'Novo',
    component_player_product_placement: 'Ta video vsebuje oglaševanje izdelkov',
    component_player_private_media: 'Zasebni mediji',
    component_search_no_results: 'Ni rezultatov iskanja',
    'Pořady': 'Serije',
    'Kategorie': 'Kategorije',
  },
}

// A computed property to get the current translation based on the current locale
const translated = computed(() => translations[currentLocale.value])

// A function to set the current locale
const setLocale = (locale) => {
  if (availableLocales.includes(locale)) {
    currentLocale.value = locale
  }
}

// A function to get a translation for a given key
export const $$t = (key) => {
  return translated.value[key] || key
}

export const changeLanguage = (locale) => {
  setLocale(locale)
}
