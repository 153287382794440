import { FontStyleModel } from '@/models/general/FontModel'
import { getBorder } from '@/models/general/BorderModel'
import Palette from '~/models/palette/Palette'

export class SearchStyleModel {
  constructor() {
    this.padding = null
    this.margin = null
    this.border = null
    this.borderRadius = null
    this.fontStyle = null
    this.color = null
    this.backgroundColor = null
    this.placeholderColor = ' '

  }

  fromData({
    padding = null,
    margin = null,
    borderProperties = null,
    fontProperties = null,
    color = null,
    backgroundColor = null,
    placeholderColor = '',
  }) {
    this.color = color

    const palette1 = new Palette()
    palette1.checkColor(backgroundColor)
    this.backgroundColor = palette1.color

    const palette2 = new Palette()
    palette2.checkColor(placeholderColor)
    this.placeholderColor = palette2.color

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius

      const palette3 = new Palette()
      palette3.checkColor(borderColor)
      this.borderColor = palette3.color
      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }

    if (fontProperties) {
      const fontStyle = new FontStyleModel()
      fontStyle.fromData(fontProperties)
      this.fontStyle = fontStyle
    }
  }
}
