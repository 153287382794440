import { FontStyleModel } from '@/models/general/FontModel'
import Palette from '~/models/palette/Palette'
import { getBorder } from '@/models/general/BorderModel'

export class ProfileStyleModel {
  constructor() {
    this.padding = null
    this.margin = null
    this.border = null
    this.borderRadius = null
    this.fontStyle = null
    this.backgroundColorOnHover = null
  }

  fromData({
    padding = null,
    margin = null,
    borderProperties = null,
    fontProperties = null,
    backgroundColorOnHover = null,
  }) {

    const palette = new Palette()
    palette.checkColor(backgroundColorOnHover)
    this.backgroundColorOnHover = palette.color

    if (padding)
      this.padding = `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`
    if (margin)
      this.margin = `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`

    if (borderProperties) {
      const { borderRadius, borderColor, borderStyle, borderWidth } = getBorder(borderProperties)
      this.borderRadius = borderRadius

      const palette1 = new Palette()
      palette1.checkColor(borderColor)
      this.borderColor = palette1.color

      this.borderStyle = borderStyle || 'solid'
      this.borderWidth = borderWidth
    }

    if (fontProperties) {
      const fontStyle = new FontStyleModel()
      fontStyle.fromData(fontProperties)
      this.fontStyle = fontStyle
    }
  }
}
